<template>
  <div v-if="page_loading">
    <loading :page_loading="page_loading" />
  </div>
  <div v-else class="mt-0" align="center">

    <!-- page 1 -->
    <div  v-for="(item) in dataPrint" :key="item.id" >
      <v-card :elevation="0" width="793" v-if="dataPrint_Count > 0">
        <v-card-text>
          <v-row class="py-3">
            <v-col cols="8">
              <v-row>
                {{ check_logo(item.branchLogo) }}
                <v-col v-if="branchLogo != null && branchLogo != '' " :cols="cols1" >
                  <v-img
                      :src="branchLogo"
                      aspect-ratio="1"
                      class="grey lighten-2"
                      max-width="50"
                      max-height="50"
                  ></v-img>
                </v-col>
                <v-col :cols="cols2" >
                  <v-row>
                    <v-col cols="12" align="left" class="font-weight-bold">{{ item.branchName }}</v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" align="left" >{{ item.branchAddress + ' ' + item.branchSubDistrict + ' ' + item.branchDistrict + ' ' + item.branchProvince + ' ' + item.branchPostcode}}</v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" align="left">เลขผู้เสียภาษี {{ item.branchTaxID }}</v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="4">
              <v-row>
                <v-col cols="12" align="right" class="font-weight-bold">
                  <h3 v-if="print_type == 1">ใบแจ้งหนี้</h3>
                  <h3 v-else>ใบเสร็จรับเงิน / ใบกำกับภาษี</h3>
                  <!-- <span v-if="item.header1 == null"> <h3>ใบแจ้งหนี้ / ใบกำกับภาษี</h3> </span>
                  <span v-else>{{ item.header1 }} {{ item.header2 == null ? "" : " / " + item.header2 }}  </span> -->
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" align="left" ></v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row class="py-3">
            <v-col cols="8">
              <v-row>
                <v-col cols="3" align="left" class="font-weight-bold">ชื่อลูกค้า : </v-col>
                <v-col cols="9" align="left" >{{ item.customerName }}</v-col>
              </v-row>
              <v-row>
                <v-col cols="3" align="left" class="font-weight-bold">ที่อยู่ : </v-col>
                <v-col cols="9" align="left" >{{ item.customerAddress + ' ' + item.customerSubDistrict + ' ' + item.customerDistrict + ' ' + item.customerProvince + ' ' + item.customerPostcode}}</v-col>
              </v-row>
              <v-row>
                <v-col cols="3" align="left" class="font-weight-bold">เลขผู้เสียภาษี : </v-col>
                <v-col cols="9" align="left" >
                  <span v-if="item.customerTaxID != null">{{ item.customerTaxID }}</span>
                  <span v-else>-</span>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="4">
              <v-row>
                <v-col cols="5" align="left" class="font-weight-bold">เลขที่ : </v-col>
                <v-col cols="7" align="left">
                  <span v-if="print_type == 1">{{ item.invoiceNo }} </span>
                  <span v-else>{{ item.invoiceNo.replace('INV', 'RC') }}</span>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="5" align="left" class="font-weight-bold">วันที่ : </v-col>
                <v-col cols="7" align="left">
                  {{ item.invoiceDate }}
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <div class="py-3">
            <table width="100%" border="0" style="border-collapse: collapse;">
              <tr class="border-a">
                <th align="center" class="py-2 border-right">
                  ลำดับ<br>
                  No
                </th>
                <th width="55%" align="center" class="py-2 border-right">
                  รายการ<br>
                  Order
                </th>
                <th width="15%" align="center" class="py-2 border-right">
                  จำนวน<br>
                  Quantity
                </th>
                <th width="15%" align="center" class="py-2 border-right">
                  ราคาต่อหน่วย <br>
                  Unit Price
                </th>
                <th width="15%" align="center" class="py-2 border-right">
                  จำนวนเงิน <br>
                  Amount
                </th>
              </tr>
              <tr  Valign="top" v-for="(item2) in item.itemList" :key="item2.id">
                <td align="center" class="px-2 border-x">{{ item.itemList.indexOf(item2) + 1 }}</td>
                <td align="left" class="px-2 border-right">{{item2.description}}</td>
                <td align="right" class="px-2 border-right">{{ item2.quantity }}</td>
                <td align="right" class="px-2 border-right">{{ formatMoney(item2.unitPrice.toFixed(2)) }}</td>
                <td align="right" class="px-2 border-right">{{ formatMoney((item2.quantity*item2.unitPrice).toFixed(2)) }}</td>
              </tr>
              <tr :height="calendar_last_row_height(item.itemList.length)"  Valign="top" class="border-bottom">
                <td align="left" class="px-2 border-x"></td>
                <td align="left" class="px-2 border-right"></td>
                <td align="right" class="px-2 border-right"></td>
                <td align="right" class="px-2 border-right"></td>
                <td align="right" class="px-2 border-right"></td>
              </tr>
              <tr Valign="top" class="border-bottom">
                <td align="left" class="px-2 py-2 border-a" colspan="2">
                  <span class="font-weight-bold">หมายเหต / remark</span><br>
                  <span>-</span>
                </td>
                <td align="left" colspan="2" class="px-2 py-2 border-a">
                  <v-row>
                    <v-col cols="12" align="left" class="font-weight-bold">ยอดรวม</v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" align="left" class="font-weight-bold">ยอดที่คำนวนภาษี</v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" align="left" class="font-weight-bold">ภาษีมูลค่าเพิ่ม
                      <span v-if="item.vatType == 1">(No VAT)</span>
                      <span v-if="item.vatType == 2">(VAT Included)</span>
                      <span v-if="item.vatType == 3">(VAT Excluded)</span>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" align="left" class="font-weight-bold">ยอดรวมทั้งหมด</v-col>
                  </v-row>
                </td>
                <td align="left" class="px-2 py-2 border-a">
                  <v-row>
                    <v-col cols="12" align="right"><span v-if="item.totalItemPrice!= null">{{ item.totalItemPrice }}</span> <span v-else>0</span></v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" align="right"> <span v-if="item.calculatedPrice!= null">{{ item.calculatedPrice }}</span> <span v-else>0</span> </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" align="right"><span v-if="item.vatAmount!= null">{{ item.vatAmount }}</span> <span v-else>0</span></v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" align="right"><span v-if="item.totalPrice!= null">{{ item.totalPrice }}</span> <span v-else>0</span></v-col>
                  </v-row>
                </td>
              </tr>
            </table>
          </div>

        </v-card-text>
      </v-card>
      <!-- page 2 -->
      <div style="break-after:page"></div>
    </div>


  </div>
</template>

<script>
  import axios from 'axios'
  import { header_token } from '@/website/token'
  import Loading from '@/website/components/Loading'
  import { financialService, reportService_dotnet } from '@/website/global'
  import { DateNow, FormatInvoice, formatMoney, set_format_date_time } from '@/website/global_function'
  export default {
    components: {
      Loading
    },
    data: () => ({
      page_loading: true,
      loading: false,

      dataPrint_Count: 0,
      dataPrint: [],
      headers: [
        { text: 'ออเดอร์', align: 'center', value: 'ID', sortable: false },
        { text: 'เลขแทรค', align: 'center', value: 'TrackingNo', sortable: false },
        { text: 'ค่าส่ง', align: 'right', value: 'DeliveryPrice', sortable: false },
        { text: 'ค่าพื้นที่ห่างไกล', align: 'right', value: 'RemotePrice', sortable: false },
        { text: 'ค่า COD', align: 'right', value: 'CODCharged', sortable: false },
        { text: 'ค่ากล่อง', align: 'right', value: 'BoxPrice', sortable: false },
        { text: 'ค่าแพค', align: 'right', value: 'PackPrice', sortable: false },
        { text: 'ส่วนลด', value: 'Discount', sortable: false, align: 'right' },
        { text: 'ราคารวม', align: 'right', value: 'TotalPrice', sortable: false },
      ],
      dataList: [],

      show_page1: true,
      show_page2: true,
      show_page3: true,

      branchLogo: null,
      cols1 : 2,
      cols2 : 10,
    }),
    async created () {
      this.inv_id = null
      this.groupID = null
      if(this.$route.query.inv === 'undefined'){
        this.inv_id = null
      } else if ( this.$route.query.inv == 'null') {
        this.inv_id = null
      } else if ( this.$route.query.inv == null) {
        this.inv_id = null
      } else if (this.$route.query.inv == '') {
        this.inv_id = null
      } else {
        this.inv_id = this.$route.query.inv
      }

      if(this.$route.query.group === 'undefined'){
        this.groupID = null
      } else if ( this.$route.query.group == 'null') {
        this.groupID = null
      } else if ( this.$route.query.group == null) {
        this.groupID = null
      } else if (this.$route.query.group == '') {
        this.groupID = null
      } else {
        this.groupID = this.$route.query.group
      }

      if(this.groupID != null){
        this.inv_id = null
      }

      this.print_type = this.$route.query.type
      await this.get_data_print(this.inv_id, this.groupID)
      this.page_loading = false
      setTimeout(function(){ print() }, 500);
      if(this.inv_id == null && this.groupID == null){
        window.close();
      }
    },
    watch: {
    },
    methods: {
      FormatInvoice,
      formatMoney,
      calendar_last_row_height (row_len) {
        if(row_len > 0){
          var max_height = 630
          var row_data = row_len
          var fix_height = 19.44
          var res =  (fix_height*row_data)
          return max_height - res
        } else {
          return 630
        }
      },
      async get_data_print(invoiceID, invoiceGroupID){
        this.loading = true
        let res1 = await axios.post(reportService_dotnet+'HQInvoice/invoice-print', {
           "hqInvoiceID":  invoiceID,
           "hqInvoiceGroupID":  invoiceGroupID
        },
        { headers: header_token})
        this.dataPrint = res1.data.invoiceList
        this.dataPrint_Count = res1.data.invoiceList.length
          this.loading = false
      },
      check_logo (branch_logo) {
        // branch_logo = 'https://packhaistorage.s3-ap-southeast-1.amazonaws.com/image/dhl.PNG'
        if(branch_logo == null){
          this.branchLogo = branch_logo
          this.cols2 = 12
        } else {
          this.branchLogo = branch_logo
          this.cols1 = 2
          this.cols2 = 10
        }
      },
    },
  }
</script>

<style scoped>
  >>>.set-font-size thead tr th{
    font-weight: bold !important;
    font-size: 11px !important;
  }
  >>>.border-left{
    border-left: 1px solid black !important;
  }
  >>>.border-right{
    border-right: 1px solid black !important;
  }
  >>>.border-top{
    border-top: 1px solid black !important;
  }
  >>>.border-bottom{
    border-bottom: 1px solid black !important;
  }
  >>>.border-x{
    border-left: 1px solid black !important;
    border-right: 1px solid black !important;
  }
  >>>.border-y{
    border-top: 1px solid black !important;
    border-bottom: 1px solid black !important;
  }
  >>>.border-a{
    border: 1px solid black !important;
  }
</style>
